import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import { Header } from '@unione-pro/unione.commons.webpack';
import { Layout } from '@unione-pro/unione.digital.commons.ui-kit/lib/layout';
import { LayoutContent } from '@unione-pro/unione.digital.commons.ui-kit/lib/layout/layout-content.view';
import { Tab, Tabs } from '@unione-pro/unione.digital.commons.ui-kit/lib/tabs';
import { BrowserRoute } from '../../../routes/browser.routes';
import { AppFooter } from '../app-footer';

export const AppLayout: FC<{ children: React.ReactNode }> = (props) => {
  const location = useLocation();

  const value = React.useMemo((): string => {
    if (location.pathname === BrowserRoute.coursesMyFinished) {
      return BrowserRoute.coursesMy;
    }
    if (location.pathname.startsWith('/course') && !location.pathname.endsWith('/my')) {
      return BrowserRoute.courses;
    }

    return location.pathname;
  }, [location.pathname]);

  return (
    <>
      <Header />

      <Layout>
        <Tabs value={value} style={{ width: '100%', padding: '0px 24px' }}>
          <Tab
            value={BrowserRoute.profile}
            label="Профиль"
            component={(p): JSX.Element => <Link to={BrowserRoute.profile} {...p} />}
          />
          <Tab
            value={BrowserRoute.documents}
            label="Мои документы"
            component={(p): JSX.Element => <Link to={BrowserRoute.documents} {...p} />}
          />
          <Tab
            value={BrowserRoute.coursesMy}
            label="Мои курсы"
            component={(p): JSX.Element => <Link to={BrowserRoute.coursesMy} {...p} />}
          />
          <Tab
            value={BrowserRoute.courses}
            label="Каталог курсов «Цифровые профессии»"
            component={(p): JSX.Element => <Link to={BrowserRoute.courses} {...p} />}
          />
        </Tabs>

        <LayoutContent>{props.children}</LayoutContent>
      </Layout>

      <AppFooter />
    </>
  );
};
