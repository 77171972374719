import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { IDigitalCourse } from '@unione-pro/unione.digital.commons.sdk';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/root/root.context';
import { PageLoadingView } from '../../lab/page-loading';
import { PageNotFound } from '../../lab/page-not-found';

const PREFIX = 'CourseLayout';
const useStyles = createUseStyles(
  () => ({
    root: {
      padding: 32,
      marginTop: -40,
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
  }),
  { name: PREFIX },
);

export const CourseLayoutNoLogic: React.FC<React.PropsWithChildren> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export const CourseLayout: React.FC<{ children: (course: IDigitalCourse) => JSX.Element }> = observer(
  ({ children }) => {
    const { digital } = useContext(appStoreContext);
    const { id } = useParams();

    const { data, loading } = digital.courseItem.useQuery({ variables: { id } });

    if (loading) {
      return <PageLoadingView />;
    }

    if (!data && !loading) {
      return <PageNotFound />;
    }

    return <CourseLayoutNoLogic>{children(data)}</CourseLayoutNoLogic>;
  },
);

export const CourseEditLayout: React.FC<{ children: (course?: IDigitalCourse, isCreate?: boolean) => JSX.Element }> = observer(
  ({ children }) => {
    const { digital } = useContext(appStoreContext);
    const { id } = useParams();
    const isCreate = id === 'new';

    const { data, loading } = digital.courseItem.useQuery({ variables: { id }, skip: isCreate });

    if (loading && !isCreate) {
      return <PageLoadingView />;
    }

    if (!data && !loading && !isCreate) {
      return <PageNotFound />;
    }

    return <CourseLayoutNoLogic>{children(data, isCreate)}</CourseLayoutNoLogic>;
  },
);
