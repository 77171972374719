import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CourseStatus, IDigitalCourse } from '@unione-pro/unione.digital.commons.sdk';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Tab, Tabs } from '@unione-pro/unione.digital.commons.ui-kit/lib/tabs';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { BrowserRoute } from '../../../routes/browser.routes';
import { formatLink, normalizeLink } from '../../../shared/link';
import { Banner } from '../course-banner';
import { OrganizationBreadcrumbs } from '../organization-breadcrumbs';

const PREFIX = 'CourseBlock';
const useStyles = createUseStyles(
  () => ({
    root: { width: '100%', overflow: 'hidden' },
    content: { padding: 40, paddingTop: 30 },
  }),
  { name: PREFIX },
);

type CourseBlockProps = React.PropsWithChildren & { right?: React.ReactNode; course: IDigitalCourse };

export const CourseBlock: FC<CourseBlockProps> = (props) => {
  const { children, course } = props;

  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();

  return (
    <>
      <OrganizationBreadcrumbs organizationId={course.organization_id} />

      <Paper className={classes.root}>
        <Banner course={course} status={course.status || CourseStatus.draft} />

        <div className={classes.content}>
          <Tabs value={location.pathname}>
            <Tab
              label="Информация о курсе"
              value={BrowserRoute.course(id)}
              component={(p): JSX.Element => <Link to={BrowserRoute.course(id)} {...p} />}
            />
            <Tab
              label="Компетенции"
              value={BrowserRoute.courseCompetencies(id)}
              component={(p): JSX.Element => <Link to={BrowserRoute.courseCompetencies(id)} {...p} />}
            />
            <Tab
              label="Модули курса"
              value={BrowserRoute.courseModules(id)}
              selected={location.pathname.startsWith(BrowserRoute.courseModules(id))}
              component={(p): JSX.Element => <Link to={BrowserRoute.courseModules(id)} {...p} />}
            />
            <Tab
              label="Преподаватели"
              value={BrowserRoute.courseTeachers(id)}
              component={(p): JSX.Element => <Link to={BrowserRoute.courseTeachers(id)} {...p} />}
            />
          </Tabs>

          <div style={{ marginTop: 40, display: 'flex', gap: 52 }}>
            <div style={{ width: '100%' }}>{children}</div>

            <Stack direction="column" spacing={16} style={{ width: 320 }}>
              <Paper variant="outlined" style={{ padding: 24, backgroundColor: '#FBFDFF' }}>
                <Stack direction="column" spacing={16}>
                  <Typography variant="label">Ответственный за программу</Typography>
                  <Typography fontWeight="medium">{course.responsible.name || '-'}</Typography>
                  <Typography>{course.responsible.phone || '-'}</Typography>
                  <Typography>{course.responsible.email || '-'}</Typography>
                </Stack>
              </Paper>

              <Paper variant="outlined" style={{ padding: 24, backgroundColor: '#FBFDFF' }}>
                <Stack direction="column" spacing={16}>
                  <Typography variant="label">Лендинг курса</Typography>
                  <a
                    style={{ color: '#0071CE' }}
                    href={normalizeLink(course.landing_url) || '#'}
                    target="_blank"
                    referrerPolicy="no-referrer"
                    rel="noreferrer"
                  >
                    {formatLink(course.landing_url)}
                  </a>
                </Stack>
              </Paper>
            </Stack>
          </div>
        </div>
      </Paper>
    </>
  );
};
