import React from 'react';
import { createUseStyles } from 'react-jss';
import { IDigitalCourse } from '@unione-pro/unione.digital.commons.sdk';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { observer } from 'mobx-react';
import { toHumanDate } from '../../../../shared/human-date';
import { AccordionList } from '../../../lab/accordion-list';
import { ListWithDots } from '../../../lab/list-with-dots';

export type CourseMainPreviewProps = { course: IDigitalCourse };

const PREFIX = 'CourseMainPreview';
const useStyles = createUseStyles(
  (theme) => ({
    root: {},
    listItem: {
      pointerEvents: 'none',
    },
    li: {
      listStyle: 'inside',
      '&::marker': {
        color: theme.palette.primary.main,
      },
    },
  }),
  { name: PREFIX },
);

export const CourseMainPreview: React.FC<CourseMainPreviewProps> = observer((props) => {
  const { course } = props;
  const classes = useStyles();

  const competenciesKnow = (course.competencies || []).map((el) => el.know);
  const competenciesBeAble = (course.competencies || []).map((el) => el.be_able);
  const resultDescription = course.result_description?.split('.').filter((item) => item);
  const requirements = [
    `Высшее образование: ${course.requirements?.higher_education ? 'да' : 'нет'}`,
    `Среднее специальное образование: ${course.requirements?.middle_education ? 'да' : 'нет'}`,
    `Квалификация: ${course.requirements?.qualification ? 'да' : 'нет'}`,
    `Наличие опыта профессиональной деятельности: ${course.requirements?.experience ? 'да' : 'нет'}`,
    `Предварительное освоение иных дисциплин/курсов/модулей: ${course.requirements?.other_courses ? 'да' : 'нет'}`,
    `Владение необходимыми профессиональными компетенциями: ${course.requirements?.prof_competencies ? 'да' : 'нет'}`,
    `Иные требования и рекомендации для обучения по программе: ${course.requirements?.other ? 'да' : 'нет'}`,
  ];

  return (
    course &&
    <Stack direction="column" spacing={32}>
      <Stack direction="row" spacing={32} style={{ backgroundColor: '#F5FBFF', padding: 24, borderRadius: '12px' }}>

        <Stack direction="column" spacing={8} style={{ flex: 1 }}>
          <Typography variant="label">окончание приема заявок</Typography>
          <Typography fontWeight="medium">{course.closed_at ? toHumanDate(course.closed_at) : '-'}</Typography>
        </Stack>
      </Stack>

      <Typography style={{ wordBreak: 'break-word' }} fontWeight="medium">{course.annotation || '-'}</Typography>

      <Typography style={{ wordBreak: 'break-word' }}>{course.description || '-'}</Typography>

      <AccordionList
        label="Планируемые результаты обучения"
        children={<ListWithDots style={{ padding: 32 }} options={resultDescription} />}
      />
      <AccordionList
        label="Вы будете знать"
        children={<ListWithDots style={{ padding: 32 }} options={competenciesKnow} />}
      />
      <AccordionList
        label="Вы будете уметь"
        children={<ListWithDots style={{ padding: 32 }} options={competenciesBeAble} />}
      />
      <AccordionList
        label="Требования к уровню подготовки слушателя"
        children={<ListWithDots style={{ padding: 32 }} options={requirements} />}
      />
    </Stack>
  );
});
