import { makeAutoObservable } from 'mobx';
import { EnvKeys, IConfigStore } from './config.models';

export class ConfigStore implements IConfigStore {

  public readonly digitalAPI: string;
  public readonly studentURL: string;

  public readonly websocketURL: string;
  public readonly imagesStorage: string;
  public readonly filesStorage: string;

  public readonly organizationUpload: string;

  constructor() {
    const storage = this.getRequired(EnvKeys.fileStorage);

    this.websocketURL = this.getRequired(EnvKeys.websocketURL);
    this.digitalAPI = this.getRequired(EnvKeys.digitalAPI);
    this.studentURL = this.getRequired(EnvKeys.studentURL);

    this.imagesStorage = `${storage}/images`;
    this.filesStorage = `${storage}/files`;

    this.organizationUpload = `${this.digitalAPI}/organization/upload`;

    makeAutoObservable(this);
  }

  private getValue(key: EnvKeys): string | undefined {
    const value = process.env[key];
    return value;
  }

  private getRequired(key: EnvKeys): string {
    const value = this.getValue(key);
    if (typeof value !== 'string' || value.length === 0) {
      throw new Error(`Cannot find "${key}" parameter from environment`);
    }
    return value;
  }


}
