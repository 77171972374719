import React, { FC, Fragment, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { LayoutSimple } from '../app-layout/layout-simple.view';
import { AuthLoader } from './access-loader.view';

export const Auth: FC<PropsWithChildren> = observer((props) => {
  const { digital, auth } = useAppStore();

  const { loading, data: user } = digital.user.useQuery({ variables: {}, skip: !auth.token });

  React.useEffect(() => {
    if (!auth.token) {
      auth.load().then().catch();
    }
  }, []);

  if (auth.loaded ? loading && auth.token : true) {
    return (
      <LayoutSimple>
        <AuthLoader />
      </LayoutSimple>
    );
  }

  return <Fragment>{props.children}</Fragment>;
});
