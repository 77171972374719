import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../../stores/root/root.context';
import { CourseBlock } from '../../../components/course-block';
import { CourseLayoutNoLogic } from '../../../components/course-layout';
import { PageLoadingView } from '../../../lab/page-loading';
import { PageNotFound } from '../../../lab/page-not-found';
import { CourseModulesPreview } from './course-modules.preview';

export const CourseModulesPage = observer(() => {
  const { digital } = useContext(appStoreContext);
  const { id, moduleId } = useParams();

  const { data: course, loading } = digital.courseItem.useQuery({ variables: { id } });

  if (loading) {
    return <PageLoadingView />;
  }

  if (!course && !loading) {
    return <PageNotFound />;
  }

  return (
    <CourseLayoutNoLogic>
      <CourseBlock course={course}>
        <CourseModulesPreview course={course} />
      </CourseBlock>
    </CourseLayoutNoLogic>
  );
});
