import React from 'react';
import { createUseStyles } from 'react-jss';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import cn from 'classnames';
import { ListWithDotsProps } from './list-with-dots.models';

const PREFIX = 'ListWithDots';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: 8,

      '& > li': {
        paddingLeft: 6,
        listStyle: 'disc',
        marginLeft: 16,

        '&::marker': {
          color: theme.palette.primary.main,
        },
      },
    },
  }),
  { name: PREFIX },
);

export const ListWithDots: React.FC<ListWithDotsProps> = (exampleProps) => {
  const { className, options, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return (
    <ul className={rootCN} {...other}>
      {options.map((el, i) => (
        <li key={`item-1-${i}`}>
          <Typography color="textPrimary">{el}</Typography>
        </li>
      ))}
    </ul>
  );
};
