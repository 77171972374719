import React from 'react';
import { createUseStyles } from 'react-jss';
import { IDigitalUser } from '@unione-pro/unione.digital.commons.sdk';
import { Button } from '@unione-pro/unione.digital.commons.ui-kit';
import { Alert } from '@unione-pro/unione.digital.commons.ui-kit/lib/alert';
import { useDialogState } from '@unione-pro/unione.digital.commons.ui-kit/lib/dialog/dialog.hook';
import { Divider } from '@unione-pro/unione.digital.commons.ui-kit/lib/divider';
import { FileRow } from '@unione-pro/unione.digital.commons.ui-kit/lib/file-row';
import { onErrorSnackbar, snackbar } from '@unione-pro/unione.digital.commons.ui-kit/lib/snackbar/snackbar.utils';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import cn from 'classnames';
import { getFileName } from '../../../shared/format/get-file-name';
import { useAppStore } from '../../../stores/root/root.context';
import { DialogDocumentUpload } from '../../components/dialog-document-upload';

type IDialogUploadType = keyof IDigitalUser['attachments'];

const formatDialogUploadType = (type: IDialogUploadType): string => ({
  passport: 'Паспорт',
  diploma: 'Диплом',
  additional_documents: 'Дополнительные документы',
  proctoring_photo: 'Фото для прокторинга',
}[type]);

const PREFIX = 'UserDocumentsPreview';
const useStyles = createUseStyles(() => ({ root: {} }), { name: PREFIX });

export const UserDocumentsPreview: React.FC<{ user: IDigitalUser;errorMessage?: string; }> = (props) => {
  const { user, errorMessage } = props;

  const classes = useStyles();
  const { digital } = useAppStore();

  const rootCN = cn(classes.root);

  const dialogUpload = useDialogState<{ type: IDialogUploadType; files: string[] }>({ type: 'passport', files: [] });

  const { refetch: refetchUser } = digital.user.useQuery({ variables: {}, skip: true });

  const [update] = digital.userUpdateAttachments.useMutation({ onError: onErrorSnackbar });

  const handleUpload
    = (type: IDialogUploadType): ((e: React.MouseEvent) => void) => (e: React.MouseEvent): void => {
      dialogUpload.open({ type, files: user.attachments?.[type] || [] });
    };

  const handleLoadFiles = (files: string[]): void => {
    update({ id: user._id, [dialogUpload.data.type]: files }).then((): void => {
      snackbar({ message: 'Успешно обновлено' });
      dialogUpload.close();
      refetchUser();
    });
  };

  return (
    <div className={rootCN}>
      <Stack direction="column" spacing={32} style={{ flex: 2, marginTop: 32, maxWidth: 912 }} divider={<Divider />}>
        {errorMessage && (
          <Alert severity="warning" variant="outlined">
            {errorMessage}
          </Alert>
        )}

        <div>
          <Typography fontWeight="bold" style={{ marginBottom: 24 }}>
            Паспорт
          </Typography>

          <Stack direction="column" spacing="12px">
            {user.attachments?.passport?.map((el) => (
              <FileRow key={`passport-${el}`} label={getFileName(el)} href={el} />
            ))}
            <Button variant="outlined" style={{ marginTop: 12 }} onClick={handleUpload('passport')}>
              Загрузить файл
            </Button>
          </Stack>
        </div>
        <div>
          <Typography fontWeight="bold" style={{ marginBottom: 24 }}>СНИЛС</Typography>

          <Stack direction="column" spacing="12px">
            {user.attachments?.snils?.map((el) => (
              <FileRow key={`snils-${el}`} label={getFileName(el)} href={el} />
            ))}
            <Button variant="outlined" style={{ marginTop: 12 }} onClick={handleUpload('snils')}>
              Загрузить файл
            </Button>
          </Stack>
        </div>

        <div>
          <Typography fontWeight="bold" style={{ marginBottom: 24 }}>
          Документ о смене персональных данных
          </Typography>

          <Stack direction="column" spacing="12px">
            {user.attachments?.data_changed?.map((el) => (
              <FileRow key={`data-change-${el}`} label={getFileName(el)} href={el} />
            ))}
            <Button variant="outlined" style={{ marginTop: 12 }} onClick={handleUpload('data_changed')}>
              Загрузить файл
            </Button>
          </Stack>
        </div>

        <div>
          <Typography fontWeight="bold" style={{ marginBottom: 24 }}>
            Диплом
          </Typography>

          <Stack direction="column" spacing="12px">
            {user.attachments?.diploma?.map((el) => (
              <FileRow key={`diploma-${el}`} label={getFileName(el)} href={el} />
            ))}
            <Button variant="outlined" style={{ marginTop: 12 }} onClick={handleUpload('diploma')}>
              Загрузить файл
            </Button>
          </Stack>
        </div>
        <div>
          <Typography fontWeight="bold" style={{ marginBottom: 24 }}>
            Дополнительные документы
          </Typography>

          <Stack direction="column" spacing="12px">
            {user.attachments?.additional_documents?.map((el) => (
              <FileRow key={`additional_documents-${el}`} label={getFileName(el)} href={el} />
            ))}
            <Button variant="outlined" style={{ marginTop: 12 }} onClick={handleUpload('additional_documents')}>
              Загрузить файл
            </Button>
          </Stack>
        </div>
        <div>
          <Typography fontWeight="bold" style={{ marginBottom: 24 }}>
            Фото для прокторинга
          </Typography>

          <Stack direction="column" spacing="12px">
            {user.attachments?.proctoring_photo?.map((el) => (
              <FileRow key={`proctoring-${el}`} label={getFileName(el)} href={el} />
            ))}
            <Button variant="outlined" style={{ marginTop: 12 }} onClick={handleUpload('proctoring_photo')}>
              Загрузить файл
            </Button>
          </Stack>
        </div>
      </Stack>

      <DialogDocumentUpload
        {...dialogUpload.props}
        files={dialogUpload.data.files}
        title={formatDialogUploadType(dialogUpload.data.type)}
        onSubmit={handleLoadFiles}
      />
    </div>
  );
};
