import React from 'react';
import { createUseStyles } from 'react-jss';
import { IDigitalCourse } from '@unione-pro/unione.digital.commons.sdk';
import { Chip } from '@unione-pro/unione.digital.commons.ui-kit/lib/chip';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { AccordionList } from '../../../lab/accordion-list';

export type CourseModulesPreviewProps = { course: IDigitalCourse };

const PREFIX = 'CourseModulesPreview';
const useStyles = createUseStyles(
  (theme) => ({
    root: {},
    chip: {
      backgroundColor: '#F5FBFF',
      color: theme.palette.primary.main,
    },
  }),
  { name: PREFIX },
);

export const CourseModulesPreview: React.FC<CourseModulesPreviewProps> = (props) => {
  const { course } = props;
  const classes = useStyles();

  const modules = course.modules || [];

  const getCompetencyTitle = (id: string): string => course.competencies.find((comp) => comp._id === id).title;

  return (
    <Stack direction="column" spacing={32}>
      {modules.map((module) => (
        <AccordionList
          label={module.title}
          children={
            <Stack style={{ padding: 24 }} direction="column" spacing={16}>
              <Chip
                className={classes.chip}
                label={getCompetencyTitle(module.competency_id)}
              />
              <Typography>{module.description}</Typography>
            </Stack>
          }
        />
      ))}
    </Stack>
  );
};
