import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../../stores/root/root.context';
import { CourseBlock } from '../../../components/course-block';
import { CourseLayoutNoLogic } from '../../../components/course-layout';
import { PageLoadingView } from '../../../lab/page-loading';
import { PageNotFound } from '../../../lab/page-not-found';
import { CourseMainPreview } from './course-main.preview';

export const InfoBlock: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => (
  <div style={{ flex: 1 }}>
    <Typography variant="caption" color="textSecondary" style={{ marginBottom: 4 }}>
      {label}
    </Typography>
    {typeof value === 'string' ? <Typography color="textPrimary">{value}</Typography> : value}
  </div>
);

export const CourseMainInfoPage = observer(() => {
  const { digital } = useContext(appStoreContext);
  const { id } = useParams();

  const { data: course, loading } = digital.courseItem.useQuery({ variables: { id } });

  if (loading) {
    return <PageLoadingView />;
  }

  if (!course && !loading) {
    return <PageNotFound />;
  }

  return (
    <CourseLayoutNoLogic>
      <CourseBlock course={course}>
        <CourseMainPreview course={course} />
      </CourseBlock>
    </CourseLayoutNoLogic>
  );
});
