import React, { FC } from 'react';
import { IDigitalOrganization } from '@unione-pro/unione.digital.commons.sdk';
import { Autocomplete, AutocompleteProps } from '@unione-pro/unione.digital.commons.ui-kit/lib/autocomplete';
import { observer } from 'mobx-react';
import { useSearch } from '../../../shared/hooks/useSearch';
import { useAppStore } from '../../../stores/root/root.context';

export type OrganizationSharedAutocompleteProps = Omit<AutocompleteProps<IDigitalOrganization>, 'options'> & {};

export const OrganizationSharedAutocomplete: FC<OrganizationSharedAutocompleteProps> = observer((props) => {
  const other = props;

  const { digital } = useAppStore();
  const search = useSearch();

  const { data: organizations } = digital.organizationList.useQuery({
    variables: { limit: 20, offset: 0, search: search.debounced },
  });

  return (
    <Autocomplete
      options={organizations || []}
      getOptionLabel={(org): string => org.title || '-'}
      placeholder="Организация"
      inputValue={search.value}
      onInputChange={search.change}
      {...other}
    />
  );
});
