import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import {
  CourseStatus,
  IDigitalCompetencyArea,
  IDigitalOrganization,
  IDigitalProgramingLanguage,
} from '@unione-pro/unione.digital.commons.sdk';
import { CircularProgress } from '@unione-pro/unione.digital.commons.ui-kit/lib/circular-progress';
import { SearchIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/search';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import {
  TablePagination,
  useTablePaginationState,
} from '@unione-pro/unione.digital.commons.ui-kit/lib/table-pagination';
import { TextField } from '@unione-pro/unione.digital.commons.ui-kit/lib/text-field';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useAppStore } from '../../../stores/root/root.context';
import { CourseCard } from '../../components/course-card';
import { CourseFilter } from '../../components/course-filter';
import { OrganizationSharedAutocomplete } from '../../components/organization-shared-autocomplete/organization-shared-autocomplete';

const PREFIX = 'OrganizationView';
const useStyles = createUseStyles(
  () => ({
    root: {},
    title: {
      padding: '32px 40px 0',
    },
    avatar: {},
    avatarEmpty: {},
    downloadBtn: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  { name: PREFIX },
);

export const CourseListPage: FC = observer(() => {
  const classes = useStyles();
  const { digital } = useAppStore();

  const [search, setSearch] = React.useState<string>('');
  const pagination = useTablePaginationState(0, 10);
  const [selectedLanguages, setSelectedLanguages] = React.useState<IDigitalProgramingLanguage[]>([]);
  const [selectedCompetencies, setSelectedCompetencies] = React.useState<IDigitalCompetencyArea[]>([]);
  const [selectedOrganization, setSelectedOrganization] = React.useState<IDigitalOrganization>();

  const { data: user } = digital.user.useQuery({ variables: {}, skip: true });
  const { data: courses, loading } = digital.courseList.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      search,
      status: CourseStatus.published,
      programming_language_ids: selectedLanguages.length === 0 ? undefined : selectedLanguages.map((el) => el._id),
      competence_ids: selectedCompetencies.length === 0 ? undefined : selectedCompetencies.map((el) => el._id),
      organization_ids: selectedOrganization ? [selectedOrganization._id] : undefined,
    },
    skip: !user,
  });

  const { data: total = 0 } = digital.courseTotal.useQuery({
    variables: {
      status: CourseStatus.published,
      programming_language_ids: selectedLanguages.length === 0 ? undefined : selectedLanguages.map((el) => el._id),
      competence_ids: selectedCompetencies.length === 0 ? undefined : selectedCompetencies.map((el) => el._id),
      organization_ids: selectedOrganization ? [selectedOrganization._id] : undefined,
    },
  });

  const { data: competencyAreas = [], loading: loadingCompetency } = digital.competency.useQuery({ variables: {} });
  const { data: programingLanguage = [], loading: loadingProgramingLang } = digital.programingLanguage.useQuery({
    variables: {},
  });

  const rows = courses || [];
  const isEmpty = rows.length === 0;

  return (
    <Paper style={{ padding: '32px 40px' }}>
      <Typography variant="h2" fontWeight="bolder" style={{ marginBottom: 32 }}>
        Каталог курсов «Цифровые профессии»
      </Typography>

      <div style={{ display: 'flex', gap: 24 }}>
        <CourseFilter
          competencyOptions={competencyAreas}
          languageOptions={programingLanguage}
          loading={loadingCompetency}
          selectedCompetencies={selectedCompetencies}
          selectedLanguages={selectedLanguages}
          onChangeCompetencies={(arr): void => setSelectedCompetencies(arr)}
          onChangeLanguages={(arr): void => setSelectedLanguages(arr)}
        />

        <div style={{ flex: 1 }}>
          <div style={{ display: 'flex', gap: 24, marginBottom: 24 }}>
            <TextField
              fullWidth
              placeholder="Введите название курса"
              InputProps={{ endAdornment: <SearchIcon /> }}
              value={search}
              onChange={(e): void => setSearch(e.target.value)}
            />
            <OrganizationSharedAutocomplete
              fullWidth
              value={selectedOrganization}
              onChange={(v): void => setSelectedOrganization(v)}
              onReset={(): void => setSelectedOrganization(undefined)}
            />
          </div>

          <div style={{ display: 'flex', gap: 24, flexWrap: 'wrap' }}>
            {!loading &&
              rows.map((course) => (
                <Link
                  key={`course-${course._id}`}
                  to={BrowserRoute.course(course._id)}
                  style={{ display: 'block', flexBasis: 'calc(50% - 12px)' }}
                >
                  <CourseCard course={course} />
                </Link>
              ))}

            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <CircularProgress />
              </div>
            )}
            {isEmpty && !loading && (
              <Typography style={{ display: 'flex', width: '100%', marginBottom: 8 }}>Курсы не найдены</Typography>
            )}
          </div>

          {(total > 10) && (
            <TablePagination
              style={{ marginTop: 32, gap: 20, marginLeft: 'auto' }}
              page={pagination.page}
              total={total}
              rowsPerPage={pagination.rowsPerPage}
              onChangePage={pagination.onChangePage}
              onChangeRowsPerPage={pagination.onChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </Paper>
  );
});
