export const normalizeLink = (url: string): string => {
  if (!url) {
    return '';
  }
  if (!url.startsWith('http')) {
    return `https://${url}`;
  }

  return url;
};

export const formatLink = (url: string): string => {
  if (!url) {
    return '';
  }

  return url.replace('https://', '').replace('http://', '');
};
