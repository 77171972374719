import { getUnioneToken } from '@unione-pro/unione.commons.webpack';
import { makeAutoObservable, runInAction } from 'mobx';
import { EnvKeys } from './config.models';
import { RootStore } from './root.store';

export class AuthStore {

  public readonly rootStore: RootStore;
  public token: string | undefined;
  public loaded: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.token = this.getValue(EnvKeys.localToken);

    if (this.token) {
      this.loaded = true;
    }

    makeAutoObservable(this);
  }

  private getValue(key: EnvKeys): string | undefined {
    const value = process.env[key];
    return value;
  }

  public async load(): Promise<void> {
    try {
      const unioneToken = await getUnioneToken();

      if (typeof unioneToken === 'string') {
        runInAction(() => {
          this.token = unioneToken as string;
          this.rootStore.digital.refresh();
        });
      }
    }
    catch (e) {
      //
    }

    this.loaded = true;
  }

}
