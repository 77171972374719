import React, { FC } from 'react';
import { Header } from '@unione-pro/unione.commons.webpack';
import { Layout } from '@unione-pro/unione.digital.commons.ui-kit/lib/layout';
import { LayoutContent } from '@unione-pro/unione.digital.commons.ui-kit/lib/layout/layout-content.view';

export const LayoutSimple: FC<{
  children: React.ReactNode;
}> = (props) => (
  <>
    <Header />

    <Layout>
      <LayoutContent>{props.children}</LayoutContent>
    </Layout>
  </>
);
