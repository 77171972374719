import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Dialog, DialogFooter, DialogHeader } from '@unione-pro/unione.digital.commons.ui-kit';
import { ChartAltIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/chart-alt';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import cn from 'classnames';
import { DialogReflectionConfirmProps } from './dialog-reflection-confirm.models';

const PREFIX = '';
const useStyles = createUseStyles(
  () => ({
    root: {
      maxWidth: '500px',
    },
    button: {
      height: 44,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
    },
  }),
  { name: PREFIX },
);

export const DialogReflectionConfirm: FC<DialogReflectionConfirmProps> = (props) => {
  const { className, onClose, onSubmit, ...other } = props;
  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const handleSubmit = (): void => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Dialog
      fullWidth
      color="primary"
      className={rootCN}
      onClose={onClose}
      {...other}
    >
      <DialogHeader onClose={onClose}>
        <ChartAltIcon color="primary" style={{ width: '28px', height: '28px' }} />
      </DialogHeader>

      <Stack direction="column" spacing={12} style={{ marginTop: 43 }}>
        <Typography fontWeight="bold" variant="h3">Итоговая рефлексия по курсу</Typography>
        <Typography variant="label">Рефлексию нужно заполнить по итогам прохождения курса, вы завершили курс?</Typography>
      </Stack>

      <DialogFooter style={{ width: '100%', marginTop: 40 }}>
        <Stack spacing={16}>
          <Button
            className={classes.button}
            fullWidth
            variant="outlined"
            onClick={onClose}
          >
            Нет
          </Button>
          <Button
            className={classes.button}
            fullWidth
            onClick={handleSubmit}
          >
            Да
          </Button>
        </Stack>
      </DialogFooter>
    </Dialog>
  );
};
