import React from 'react';
import { createUseStyles } from 'react-jss';
import { IDigitalUser } from '@unione-pro/unione.digital.commons.sdk';
import { Chip } from '@unione-pro/unione.digital.commons.ui-kit/lib/chip';
import { Divider } from '@unione-pro/unione.digital.commons.ui-kit/lib/divider';
import { FileRow } from '@unione-pro/unione.digital.commons.ui-kit/lib/file-row';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import cn from 'classnames';
import { formatPhone } from '../../../shared/format/get-phone';
import { toHumanDate } from '../../../shared/human-date';
import { getUserBirthDate } from '../../../shared/user/user-get-birthdate';
import { getUserFio } from '../../../shared/user/user-get-fio';
import { getUserGeo } from '../../../shared/user/user-get-geo';
import { AvatarProfile } from '../../lab/avatar-profile';

export type UserProfilePreviewProps = React.HtmlHTMLAttributes<HTMLDivElement> & { user: IDigitalUser };

const PREFIX = 'UserProfilePreview';
const useStyles = createUseStyles(() => ({ root: {} }), { name: PREFIX });

export const UserProfilePreview: React.FC<UserProfilePreviewProps> = (exampleProps) => {
  const { className, user, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return (
    <div className={rootCN} {...other}>
      <Stack direction="row" spacing={0}>
        <Stack style={{ paddingTop: '40px', width: 208 }}>
          <AvatarProfile src="#" />
        </Stack>

        <Stack direction="column" spacing={32} style={{ flex: 2, paddingTop: '40px' }} divider={<Divider />}>
          <div>
            <Chip label={`ID: ${user._id}`} style={{ marginBottom: 20 }} variant="standard" color="gray" rounded />
            <Typography variant="h2" fontWeight="bolder">
              {getUserFio(user)}
            </Typography>
          </div>

          <Stack direction="column" spacing={24}>
            <Typography fontWeight="bold">Личные данные</Typography>

            <Stack direction="row" spacing={24}>
              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">Дата рождения</Typography>
                <Typography>{toHumanDate(getUserBirthDate(user))}</Typography>
              </Stack>

              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">Снилс</Typography>
                <Typography>{user.user.user_info.docs.snils}</Typography>
              </Stack>

              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">Пол</Typography>
                <Typography>-</Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={24}>
              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">Телефон</Typography>
                <Typography>{formatPhone(user.user.phone)}</Typography>
              </Stack>

              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">E-mail</Typography>
                <Typography>{user.user.email}</Typography>
              </Stack>

              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">Адрес</Typography>
                <Typography>{getUserGeo(user)}</Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="column" spacing={24}>
            <Typography fontWeight="bold">Организация</Typography>

            <Stack direction="row" spacing={24}>
              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">ИНН</Typography>
                <Typography>{user.user.user_info.work.inn || '-'}</Typography>
              </Stack>

              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">ОГРН/ОГРНИП</Typography>
                <Typography>{user.user.user_info.work.ogrn || '-'}</Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={24}>
              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">Должность</Typography>
                <Typography>{user.user.user_info.work.position || '-'}</Typography>
              </Stack>

              <Stack direction="column" style={{ flex: 1 }} spacing={8}>
                <Typography variant="label">Место работы</Typography>
                <Typography>{user.user.user_info.work.place || '-'}</Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="column" spacing="12px" style={{ maxWidth: 912 }}>
            <Typography fontWeight="bold">Документы</Typography>

            <FileRow label="Справка с места работы.pdf" href={undefined} />
            <FileRow label="Скан пропуска на предприятие.pdf" href={undefined} />
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
