import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useDebounce } from 'react-use';
import { IDigitalCourseReflection } from '@unione-pro/unione.digital.commons.sdk/lib/digital/course-reflection/types';
import { Button, Dialog, DialogHeader } from '@unione-pro/unione.digital.commons.ui-kit';
import { Divider } from '@unione-pro/unione.digital.commons.ui-kit/lib/divider';
import { ArrowRightLongIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/arrow-right-long';
import { Rating } from '@unione-pro/unione.digital.commons.ui-kit/lib/rating';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { TabPanel } from '@unione-pro/unione.digital.commons.ui-kit/lib/tabs';
import { TabsLine } from '@unione-pro/unione.digital.commons.ui-kit/lib/tabs-line';
import { TextArea } from '@unione-pro/unione.digital.commons.ui-kit/lib/text-area';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import cn from 'classnames';
import { DialogRelectionProps } from './dialog-reflection.models';

const PREFIX = 'DialogReflection';
const useStyles = createUseStyles(
  () => ({
    root: {
    },
    container: {
      maxWidth: 732,
    },
    tabPanel: {
      marginTop: '32px',
      fontSize: '16px',
    },
    button: {
      marginLeft: 'auto',
      height: '44px',
      justifyContent: 'center',
      gap: 11,
      alignItems: 'center',
      fontSize: '16px',
      flexWrap: 'nowrap',
      '& > div': {
        marginLeft: 0,
      },
    },
  }),
  { name: PREFIX },
);

export const DialogReflection: FC<DialogRelectionProps> = (props) => {
  const { className, onClose, onSubmit, open, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const [tab, setTab] = React.useState<number>(0);
  const [reflection, setReflection] = React.useState<IDigitalCourseReflection | null>();

  const isReflectionFilled: boolean = React.useMemo(() => {
    if (!reflection) {
      return false;
    }

    if (
      (tab === 0 && reflection.course_usefulness > 0)
      || (tab === 1 && reflection.course_practical_tasks_reveal > 0)
      || (tab === 2 && reflection.educational_topics_submission_accessibility > 0)
      || (tab === 3 && reflection.convenience_of_the_training_platform > 0)
      || (tab === 4 && reflection.comment)
    ) {
      return true;
    }

    return false;
  }, [reflection, tab]);

  const handleTabs = (): void => {
    if (tab < 4) {
      setTab(tab + 1);
      return;
    }

    if (onSubmit) {
      onSubmit(reflection);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setTab(0);
      setReflection(null);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      ContainerProps={{ className: classes.container }}
      color="primary"
      className={rootCN}
      onClose={onClose}
      open={open}
      {...other}
    >
      <DialogHeader onClose={onClose}>
        <Typography fontWeight="bold" variant="h3">
          Итоговая рефлексия по курсу
        </Typography>
      </DialogHeader>

      <TabsLine style={{ marginTop: 32 }} value={tab} onChange={(v): void => setTab(v)} />

      <TabPanel className={classes.tabPanel} current={tab} value={0}>
        <Stack direction="column" spacing={24} style={{ flex: 1 }}>
          <Typography>
            Оцените полезность курса по 5-ти балльной шкале, где 0 означает низкую оценку, а 5 максимум
          </Typography>
          <Rating
            value={reflection?.course_usefulness || 0}
            onChange={(v): void => {
              setReflection({ ...reflection, course_usefulness: v });
              setTab(1);
            }}
          />
        </Stack>
      </TabPanel>

      <TabPanel className={classes.tabPanel} current={tab} value={1}>
        <Stack direction="column" spacing={24} style={{ flex: 1 }}>
          <Typography>
            Оцените, насколько полно раскрывают темы курса практические задания по 5-ти балльной шкале, где 0 означает
            низкую оценку, а - 5 максимум
          </Typography>
          <Rating
            value={reflection?.course_practical_tasks_reveal || 0}
            onChange={(v): void => {
              setReflection({ ...reflection, course_practical_tasks_reveal: v });
              setTab(2);
            }}
          />
        </Stack>
      </TabPanel>

      <TabPanel className={classes.tabPanel} current={tab} value={2}>
        <Stack direction="column" spacing={24} style={{ flex: 1 }}>
          <Typography>
            Оцените доступность подачи учебных тем преподавателем курса по 5-ти балльной шкале, где 0 означает низкую
            оценку, а 5 максимум
          </Typography>
          <Rating
            value={reflection?.educational_topics_submission_accessibility || 0}
            onChange={(v): void => {
              setReflection({ ...reflection, educational_topics_submission_accessibility: v });
              setTab(3);
            }}
          />
        </Stack>
      </TabPanel>

      <TabPanel className={classes.tabPanel} current={tab} value={3}>
        <Stack direction="column" spacing={24} style={{ flex: 1 }}>
          <Typography>
            Оцените удобство работы обучающей платформы провайдера по 5-ти балльной шкале, где 0 означает низкую оценку,
            а 5 максимум
          </Typography>
          <Rating
            value={reflection?.convenience_of_the_training_platform || 0}
            onChange={(v): void => {
              setReflection({ ...reflection, convenience_of_the_training_platform: v });
              setTab(4);
            }}
          />
        </Stack>
      </TabPanel>

      <TabPanel className={classes.tabPanel} current={tab} value={4}>
        <Stack direction="column" spacing={24} style={{ flex: 1 }}>
          <Typography>Поделитесь, пожалуйста, впечатлениями о пройденном курсе</Typography>

          <Stack direction="column" spacing={4} style={{ flex: 1 }}>
            <Typography variant="label">Ваш отзыв о пройденном курсе</Typography>
            <TextArea
              placeholder="Введите отзыв"
              value={reflection?.comment || ''}
              onChange={(e): void => setReflection({ ...reflection, comment: e.target.value })}
              fullWidth
            />
          </Stack>
        </Stack>
      </TabPanel>

      <Divider style={{ marginTop: '52px', marginBottom: '16px' }} />

      <Button
        onClick={handleTabs}
        className={classes.button}
        endIcon={tab < 4 && <ArrowRightLongIcon style={{ width: 20, height: 20 }} />}
        disabled={!isReflectionFilled}
      >
        {tab === 4 ? 'Отправить рефлексию' : 'Далее'}
      </Button>
    </Dialog>
  );
};
