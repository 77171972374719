import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../../stores/root/root.context';
import { CourseBlock } from '../../../components/course-block';
import { CourseLayoutNoLogic } from '../../../components/course-layout';
import { PageLoadingView } from '../../../lab/page-loading';
import { PageNotFound } from '../../../lab/page-not-found';
import { CourseTeachersPreview } from './course-teachers.preview';

export const CourseTeachersPage = observer(() => {
  const { digital } = useContext(appStoreContext);
  const { id } = useParams();

  const { data: course, loading } = digital.courseItem.useQuery({ variables: { id } });

  if (loading) {
    return <PageLoadingView />;
  }

  if (!course && !loading) {
    return <PageNotFound />;
  }

  return (
    <CourseLayoutNoLogic>
      <CourseBlock course={course}>
        <CourseTeachersPreview course={course} />
      </CourseBlock>
    </CourseLayoutNoLogic>
  );
});
