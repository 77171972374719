import React from 'react';
import { createUseStyles } from 'react-jss';
import { Avatar } from '@unione-pro/unione.digital.commons.ui-kit/lib/avatar';
import { Divider } from '@unione-pro/unione.digital.commons.ui-kit/lib/divider';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { getTextRowsStyles } from '@unione-pro/unione.digital.commons.ui-kit/lib/utils/style/get-text-rows-style';
import cn from 'classnames';
import { toJS } from 'mobx';
import { toHumanTextDate } from '../../../shared/human-date';
import { CourseCardProps } from './course-card-models';

const PREFIX = 'CourseCard';
const useStyles = createUseStyles(
  () => ({
    root: {
      padding: 20,
      background: '#FBFDFF',
      display: 'flex',
      gap: 24,
    },
    avatar: {
      width: 96,
      height: 96,
      borderRadius: 10,
      padding: 0,
    },
    title: {
      ...getTextRowsStyles(2),
    },
    box: {
      flex: 1,
    },
    stream: {
      display: 'flex',
      gap: 16,
      padding: '4px 16px',

      '& > *:first-child': {
        minWidth: 100,
      },
    },
  }),
  { name: PREFIX },
);

export const CourseCard: React.FC<CourseCardProps> = (exampleProps) => {
  const { className, course, stream, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const first = stream || (course.streams || []).sort((a, b) => (a.started_at < b.started_at ? -1 : 1))[0];

  return (
    <Paper variant="outlined" className={rootCN} {...other}>
      <Avatar src={course.media || '#'} className={classes.avatar} />
      <div className={classes.box}>
        <Typography variant="h4" fontWeight="bold" className={classes.title}>
          {course.name}
        </Typography>

        <Paper variant="outlined" style={{ marginTop: 16, width: '100%' }}>
          {first && (
            <div className={classes.stream}>
              <Typography color="textSecondary" variant="caption" noWrap>
                Поток {first.number}
              </Typography>
              <Typography color="primary" variant="caption" noWrap>
                {first.started_at > new Date()
                  ? `старт ${toHumanTextDate(first.started_at).split(' ').slice(0, 2).join(' ')}`
                  : first.ended_at > new Date()
                    ? `завершение ${toHumanTextDate(first.ended_at).split(' ').slice(0, 2).join(' ')}`
                    : 'завершен'}
              </Typography>
            </div>
          )}
          {!course.streams
            || (course.streams.length === 0 && (
              <Typography variant="caption" className={classes.stream}>
                Нет потоков
              </Typography>
            ))}
        </Paper>
      </div>
    </Paper>
  );
};
