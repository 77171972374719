import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { AppLayout } from '../views/components/app-layout';
import { CourseListPage } from '../views/content/course-list';
import { CourseCompetenciesPage } from '../views/content/course-view/course-competencies/course-competencies.view';
import { CourseMainInfoPage } from '../views/content/course-view/course-main/course-main.view';
import { CourseModulesPage } from '../views/content/course-view/course-modules/course-modules.view';
import { CourseTeachersPage } from '../views/content/course-view/course-teachers/course-teachers.view';
import { MyCoursesPage } from '../views/content/user-courses';
import { DocumentsPage } from '../views/content/user-documents';
import { ProfilePage } from '../views/content/user-profile';
import { PageNotFound } from '../views/lab/page-not-found';
import { BrowserRoute } from './browser.routes';

export const appRoutesDefinition: RouteObject[] = [
  {
    path: '/',
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),

    children: [
      {
        path: '*',
        element: <PageNotFound />,
      },
      { path: BrowserRoute.courses, element: <CourseListPage /> },
      { path: BrowserRoute.profile, element: <ProfilePage /> },
      { path: BrowserRoute.documents, element: <DocumentsPage /> },
      { path: BrowserRoute.coursesMy, element: <MyCoursesPage /> },
      { path: BrowserRoute.coursesMyFinished, element: <MyCoursesPage /> },

      { path: BrowserRoute.course(), element: <CourseMainInfoPage /> },
      { path: BrowserRoute.courseModules(), element: <CourseModulesPage /> },
      { path: BrowserRoute.courseCompetencies(), element: <CourseCompetenciesPage /> },
      { path: BrowserRoute.courseTeachers(), element: <CourseTeachersPage /> },
    ],
  },
];
