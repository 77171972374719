export enum EnvKeys {
  websocketURL = 'REACT_APP_WEBSOCKET_URL',
  fileStorage = 'REACT_APP_FILE_STORAGE',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  digitalAPI = 'REACT_APP_DIGITAL_API',
  env = 'NODE_ENV',
  studentURL = 'REACT_APP_DIGITAL_STUDENT',
}

export interface IConfigStore {
  websocketURL: string;
  filesStorage: string;
  digitalAPI: string;
  studentURL: string;
}
