import React from 'react';
import { IDigitalCourse } from '@unione-pro/unione.digital.commons.sdk';
import { Divider } from '@unione-pro/unione.digital.commons.ui-kit/lib/divider';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { AccordionList } from '../../../lab/accordion-list';
import { ListWithDots } from '../../../lab/list-with-dots';

export type CourseCompetenciesPreviewProps = { course: IDigitalCourse };

export const CourseCompetenciesPreview: React.FC<CourseCompetenciesPreviewProps> = (props) => {
  const { course } = props;

  const competencies = course.competencies || [];
  const modifyCompetencyData = (value: string): string[] => value.split('.').filter(((val) => val));

  return (
    <Stack direction="column" spacing={32}>
      {competencies.map((competency) => (
        <AccordionList
          key={competency._id}
          label={competency.title}
          children={
            <Stack style={{ padding: 24 }} direction="column" spacing={32} divider={<Divider />}>
              <div>
                <Typography color="textPrimary" fontWeight="bold" style={{ marginBottom: 24 }}>
                  Знать
                </Typography>
                <ListWithDots options={modifyCompetencyData(competency.know || '')} />
              </div>

              <div>
                <Typography color="textPrimary" fontWeight="bold" style={{ marginBottom: 24 }}>
                  Уметь
                </Typography>
                <ListWithDots options={modifyCompetencyData(competency.be_able || '')} />
              </div>

              <div>
                <Typography color="textPrimary" fontWeight="bold" style={{ marginBottom: 24 }}>
                  Владеть
                </Typography>
                <ListWithDots options={modifyCompetencyData(competency.possess || '')} />
              </div>
            </Stack>
          }
        />
      ))}
    </Stack>
  );
};
