import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Accordion, AccordionDetails, AccordionListItem, AccordionSummary } from '@unione-pro/unione.digital.commons.ui-kit/lib/accordion';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import { AccordionListProps } from './accordion-list.models';

const PREFIX = 'AccordionList';
const useStyles = createUseStyles(
  () => ({
    root: {},
    title: {
      padding: 24,
      '& > p': {
        fontWeight: 600,
        color: '#3B4168',
      },
    },
  }),
  { name: PREFIX },
);


export const AccordionList: FC<AccordionListProps> = (props) => {
  const { label, children } = props;
  const classes = useStyles();

  return (
    <>
      <Accordion variant="outlined">
        <AccordionSummary className={classes.title} label={label} />
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
