import React from 'react';
import { IDigitalCourse } from '@unione-pro/unione.digital.commons.sdk';
import { Divider } from '@unione-pro/unione.digital.commons.ui-kit/lib/divider';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { getCourseTeacherFio } from '../../../../shared/course/get-course-teacher-fio';
import { AvatarProfile } from '../../../lab/avatar-profile';

export type CourseTeachersPreviewProps = { course: IDigitalCourse };

export const CourseTeachersPreview: React.FC<CourseTeachersPreviewProps> = (props) => {
  const { course } = props;

  const teachers = course.teachers || [];

  return (
    <Stack direction="column" spacing={32} divider={<Divider />}>
      {teachers.map((teacher) => (
        <Stack key={teacher._id} direction="column" spacing={16}>
          <Stack direction="row" spacing={40} alignItems="center">
            <AvatarProfile src={teacher.photo} />
            <Stack direction="column" spacing={24} style={{ flex: 1 }}>
              <Typography variant="h4" fontWeight="bold">{getCourseTeacherFio(teacher)}</Typography>
              <Stack direction="row" spacing={24}>
                <Stack direction="column" spacing={8} style={{ flex: 1 }}>
                  <Typography variant="label">Место работы</Typography>
                  <Typography>{teacher.job}</Typography>
                </Stack>
                <Stack direction="column" spacing={8} style={{ flex: 1 }}>
                  <Typography variant="label">Место работы</Typography>
                  <Typography>{teacher.position}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
