import {
  CourseDifficulty,
  CourseStatus,
  CourseVersion,
  CourseLearnFormat,
} from '@unione-pro/unione.digital.commons.sdk';

export const courseStatusToLocale = (value: CourseStatus): string => ({
  [CourseStatus.draft]: 'Черновик',
  [CourseStatus.archived]: 'В архиве',
  [CourseStatus.published]: 'Опубликован',
  [CourseStatus.moderationDeclined]: 'Отклонен',
  [CourseStatus.moderationAccepted]: 'Принят',
  [CourseStatus.onModeration]: 'На модерации',
}[value]);
export const courseDifficultyToLocale = (value: CourseDifficulty): string => ({
  [CourseDifficulty.elementary]: 'Начальный',
  [CourseDifficulty.advanced]: 'Продвинутый',
  [CourseDifficulty.basic]: 'Базовый',
}[value]);
export const courseVersionToLocale = (value: CourseVersion): string => ({
  [CourseVersion.new]: 'Первая версия образовательной программы, ранее не реализованная',
  [CourseVersion.old]: 'Ранее реализованная версия образовательной программы',
}[value]);
export const courseLearnFormatToLocale = (value: CourseLearnFormat): string => ({
  [CourseLearnFormat.online]: 'Онлайн',
  [CourseLearnFormat.offline]: 'Оффлайн',
  [CourseLearnFormat.hybrid]: 'Онлайн и офлайн',
}[value]);
