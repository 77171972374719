import { IDigitalUser } from '@unione-pro/unione.digital.commons.sdk';

// eslint-disable-next-line
export const getUserBirthDate = (user: IDigitalUser): Date => {
  if (!(user && user.user && user.user.user_info)) {
    return new Date(''); // invalid
  }

  const date = new Date();
  date.setFullYear(user.user.user_info.birthday.year);
  date.setMonth(user.user.user_info.birthday.month - 1);
  date.setDate(user.user.user_info.birthday.day);

  return date;
};
