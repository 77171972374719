import React from 'react';
import { CircularProgress } from '@unione-pro/unione.digital.commons.ui-kit/lib/circular-progress';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { PageMessage } from '../../lab/page-message';

const iconSize = 48;

export const AuthLoader: React.FC = () => (
  <PageMessage
    message={
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress width={iconSize} height={iconSize} />
        <Typography variant="h2" align="center">
          Выполняется проверка авторизации
        </Typography>
      </div>
    }
  />
);
