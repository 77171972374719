import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { PageLoadingView } from '../../lab/page-loading';
import { PageNotFound } from '../../lab/page-not-found';
import { UserProfilePreview } from './user-profile.preview';

const PREFIX = 'OrganizationView';
const useStyles = createUseStyles(
  () => ({
    root: {},
  }),
  { name: PREFIX },
);

export const ProfilePage: FC = observer(() => {
  const classes = useStyles();
  const { digital } = useAppStore();

  const { data: user, loading } = digital.user.useQuery({ variables: {}, skip: true });

  if (loading) {
    return <PageLoadingView />;
  }

  if (!user && !loading) {
    return <PageNotFound />;
  }

  return (
    <Paper style={{ padding: 40 }}>
      <UserProfilePreview user={user} />
    </Paper>
  );
});
