import React from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '@unione-pro/unione.digital.commons.ui-kit/lib/breadcrumbs';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { OrganizationBreadcrumbsProps } from './organization-breadcrumbs.models';

const PREFIX = 'OrganizationBreadcrumbs';
const useStyles = createUseStyles(() => ({ root: {} }), { name: PREFIX });

export const OrganizationBreadcrumbs: React.FC<OrganizationBreadcrumbsProps> = observer((exampleProps) => {
  const { className, organizationId, ...other } = exampleProps;

  const { digital } = useAppStore();
  const params = useParams();

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const { data } = digital.organizationItem.useQuery({ variables: { id: organizationId } });
  const { data: course } = digital.courseItem.useQuery({ variables: { id: params.id } });

  return (
    <Breadcrumbs
      options={[{ label: 'Организации' }, { label: data ? data.title : '-' }, { label: course ? course.name : 'Цифровые профессии' }]}
      className={rootCN}
      {...other}
    />
  );
});
