import { DigitalSdk } from '@unione-pro/unione.digital.commons.sdk';
import { IDigitalSdkCompetencyAreaFindArgs, IDigitalSdkCompetencyAreaFindRes } from '@unione-pro/unione.digital.commons.sdk/lib/digital/competency';
import {
  IDigitalSdkCreateCourseArgs,
  IDigitalSdkCreateCourseRes,
  IDigitalSdkFindCoursesArgs,
  IDigitalSdkFindCoursesRes,
  IDigitalSdkGetCourseArgs,
  IDigitalSdkGetCourseRes,
  IDigitalSdkUpdateCourseArgs,
  IDigitalSdkUpdateCourseRes,
  IDigitalSdkTotalCoursesArgs,
  IDigitalSdkTotalCoursesRes,
  IDigitalSdkUnpublishCourseRes,
  IDigitalSdkUnpublishCourseArgs,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/course';
import {
  IDigitalSdkApproveCourseModerationArgs,
  IDigitalSdkApproveCourseModerationRes,
  IDigitalSdkDeclineCourseModerationArgs,
  IDigitalSdkDeclineCourseModerationRes,
  IDigitalSdkGetCourseModerationArgs,
  IDigitalSdkGetCourseModerationRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/course-moderation';
import {
  IDigitalSdkCreateCourseReflectionArgs,
  IDigitalSdkCreateCourseReflectionRes,
  IDigitalSdkExistsCourseReflectionArgs, IDigitalSdkExistsCourseReflectionRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/course-reflection';
import {
  IDigitalSdkFindDocumentsArgs,
  IDigitalSdkFindDocumentsRes,
  IDigitalSdkGetDocumentArgs,
  IDigitalSdkGetDocumentRes,
  IDigitalSdkDocumentCreateRes,
  IDigitalSdkDocumentCreateArgs,
  IDigitalSdkDocumentDeleteArgs,
  IDigitalSdkDocumentDeleteRes,
  IDigitalSdkDocumentSignArgs,
  IDigitalSdkDocumentSignRes,
  IDigitalSdkDocumentTotalArgs,
  IDigitalSdkDocumentTotalRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/document';
import {
  IDigitalSdkCreateOrganizationArgs,
  IDigitalSdkCreateOrganizationRes,
  IDigitalSdkFindOrganizationArgs,
  IDigitalSdkFindOrganizationRes,
  IDigitalSdkGetOrganizationArgs,
  IDigitalSdkGetOrganizationRes,
  IDigitalSdkTotalOrganizationArgs,
  IDigitalSdkTotalOrganizationRes,
  IDigitalSdkUpdateOrganizationArgs,
  IDigitalSdkUpdateOrganizationRes,
  IDigitalSdkFindPartOrganizationArgs,
  IDigitalSdkFindPartOrganizationRes,

} from '@unione-pro/unione.digital.commons.sdk/lib/digital/organization';
import { IDigitalSdkProgramingLanguageFindArgs, IDigitalSdkProgramingLanguageFindRes } from '@unione-pro/unione.digital.commons.sdk/lib/digital/programing-language';
import {
  IDigitalSdkCreateRequestArgs,
  IDigitalSdkCreateRequestRes,
  IDigitalSdkFindRequestArgs,
  IDigitalSdkFindRequestRes,
  IDigitalSdkGetRequestArgs,
  IDigitalSdkGetRequestRes, IDigitalSdkRequestExistsArgs, IDigitalSdkRequestExistsRes,
  IDigitalSdkTotalRequestArgs,
  IDigitalSdkTotalRequestRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/request';
import {
  IDigitalSdkFindOneIdUsersArgs,
  IDigitalSdkFindOneIdUsersRes,
  IDigitalSdkFindUsersArgs,
  IDigitalSdkFindUsersRes,
  IDigitalSdkGetUserArgs,
  IDigitalSdkGetUserRes,
  IDigitalSdkLoginUserArgs,
  IDigitalSdkLoginUserRes,
  IDigitalSdkTotalUsersArgs,
  IDigitalSdkTotalUsersRes,
  IDigitalSdkUserCreateArgs,
  IDigitalSdkUserCreateRes,
  IDigitalSdkUserDeleteArgs,
  IDigitalSdkUserDeleteRes, IDigitalSdkUserUpdateAttachmentsArgs, IDigitalSdkUserUpdateAttachmentsRes,
  IDigitalSdkUserUpdateUserArgs,
  IDigitalSdkUserUpdateUserRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/user';
import {
  IDigitalSdkUserCourseFindArgs,
  IDigitalSdkUserCourseFindRes, IDigitalSdkUserCourseGetArgs, IDigitalSdkUserCourseGetRes, IDigitalSdkUserCourseTotalArgs,
  IDigitalSdkUserCourseTotalRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/user-course';
import {
  IDigitalSdkUserModerationGetArgs,
  IDigitalSdkUserModerationGetRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/user-moderation';
import { MutationStore, QueryStore } from '@unione-pro/unione.digital.commons.sdk/lib/utils';
import { RootStore } from '../root/root.store';

export class DigitalStore {

  public readonly rootStore: RootStore;
  public readonly sdk: DigitalSdk;

  public readonly requestItem: QueryStore<IDigitalSdkGetRequestRes, IDigitalSdkGetRequestArgs>;
  public readonly requestList: QueryStore<IDigitalSdkFindRequestRes, IDigitalSdkFindRequestArgs>;
  public readonly requestTotal: QueryStore<IDigitalSdkTotalRequestRes, IDigitalSdkTotalRequestArgs>;
  public readonly requestExists: QueryStore<IDigitalSdkRequestExistsRes, IDigitalSdkRequestExistsArgs>;
  public readonly requestCreate: MutationStore<IDigitalSdkCreateRequestRes, IDigitalSdkCreateRequestArgs>;

  public readonly user: QueryStore<IDigitalSdkLoginUserRes, IDigitalSdkLoginUserArgs>;
  public readonly organization: QueryStore<IDigitalSdkGetOrganizationRes>;

  public readonly courseItem: QueryStore<IDigitalSdkGetCourseRes, IDigitalSdkGetCourseArgs>;
  public readonly courseList: QueryStore<IDigitalSdkFindCoursesRes, IDigitalSdkFindCoursesArgs>;
  public readonly createCourse: MutationStore<IDigitalSdkCreateCourseRes, IDigitalSdkCreateCourseArgs>;
  public readonly updateCourse: MutationStore<IDigitalSdkUpdateCourseRes, IDigitalSdkUpdateCourseArgs>;
  public readonly courseTotal: QueryStore<IDigitalSdkTotalCoursesRes, IDigitalSdkTotalCoursesArgs>;
  public readonly approveCourse: MutationStore<
  IDigitalSdkApproveCourseModerationRes,
  IDigitalSdkApproveCourseModerationArgs
  >;
  public readonly declineCourse: MutationStore<
  IDigitalSdkDeclineCourseModerationRes,
  IDigitalSdkDeclineCourseModerationArgs
  >;
  public readonly unPublishCourse: MutationStore<IDigitalSdkUnpublishCourseRes, IDigitalSdkUnpublishCourseArgs>;

  public readonly courseModerationItem: QueryStore<
  IDigitalSdkGetCourseModerationRes,
  IDigitalSdkGetCourseModerationArgs
  >;

  public readonly userList: QueryStore<IDigitalSdkFindUsersRes, IDigitalSdkFindUsersArgs>;
  public readonly userItem: QueryStore<IDigitalSdkGetUserRes, IDigitalSdkGetUserArgs>;
  public readonly userTotal: QueryStore<IDigitalSdkTotalUsersRes, IDigitalSdkTotalUsersArgs>;
  public readonly userDelete: MutationStore<IDigitalSdkUserDeleteRes, IDigitalSdkUserDeleteArgs>;
  public readonly userCreate: MutationStore<IDigitalSdkUserCreateRes, IDigitalSdkUserCreateArgs>;
  public readonly userChangeRole: MutationStore<IDigitalSdkUserUpdateUserRes, IDigitalSdkUserUpdateUserArgs>;
  public readonly userOneIdList: QueryStore<IDigitalSdkFindOneIdUsersRes, IDigitalSdkFindOneIdUsersArgs>;
  public readonly userUpdateAttachments: MutationStore<IDigitalSdkUserUpdateAttachmentsRes, IDigitalSdkUserUpdateAttachmentsArgs>;
  public readonly userModeration: QueryStore<IDigitalSdkUserModerationGetRes, IDigitalSdkUserModerationGetArgs>;
  public readonly userCourse: QueryStore<IDigitalSdkUserCourseGetRes, IDigitalSdkUserCourseGetArgs>;

  public readonly organizationItem: QueryStore<IDigitalSdkGetOrganizationRes, IDigitalSdkGetOrganizationArgs>;
  public readonly organizationList: QueryStore<IDigitalSdkFindOrganizationRes, IDigitalSdkFindOrganizationArgs>;
  public readonly organizationListPart: QueryStore<IDigitalSdkFindPartOrganizationRes, IDigitalSdkFindPartOrganizationArgs>;
  public readonly organizationTotal: QueryStore<IDigitalSdkTotalOrganizationRes, IDigitalSdkTotalOrganizationArgs>;
  public readonly organizationUpdate: MutationStore<
  IDigitalSdkUpdateOrganizationRes,
  IDigitalSdkUpdateOrganizationArgs
  >;
  public readonly organizationCreate: MutationStore<
  IDigitalSdkCreateOrganizationRes,
  IDigitalSdkCreateOrganizationArgs
  >;

  public readonly documentItem: QueryStore<IDigitalSdkGetDocumentRes, IDigitalSdkGetDocumentArgs>;
  public readonly documentList: QueryStore<IDigitalSdkFindDocumentsRes, IDigitalSdkFindDocumentsArgs>;
  public readonly documentTotal: QueryStore<IDigitalSdkDocumentTotalRes, IDigitalSdkDocumentTotalArgs>;
  public readonly documentCreate: MutationStore<IDigitalSdkDocumentCreateRes, IDigitalSdkDocumentCreateArgs>;
  public readonly documentSign: MutationStore<IDigitalSdkDocumentSignRes, IDigitalSdkDocumentSignArgs>;
  public readonly documentDelete: MutationStore<IDigitalSdkDocumentDeleteRes, IDigitalSdkDocumentDeleteArgs>;
  public readonly documentUpload: string;

  public readonly competency: QueryStore<IDigitalSdkCompetencyAreaFindRes, IDigitalSdkCompetencyAreaFindArgs>;

  public readonly programingLanguage: QueryStore<IDigitalSdkProgramingLanguageFindRes, IDigitalSdkProgramingLanguageFindArgs>;

  public readonly userCourseList: QueryStore<IDigitalSdkUserCourseFindRes, IDigitalSdkUserCourseFindArgs>;
  public readonly userCourseTotal: QueryStore<IDigitalSdkUserCourseTotalRes, IDigitalSdkUserCourseTotalArgs>;

  public readonly courseReflection: MutationStore<IDigitalSdkCreateCourseReflectionRes, IDigitalSdkCreateCourseReflectionArgs>;
  public readonly courseReflectionExists: QueryStore<IDigitalSdkExistsCourseReflectionRes, IDigitalSdkExistsCourseReflectionArgs>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.sdk = new DigitalSdk(this.rootStore.config.digitalAPI, this.rootStore.auth.token);

    this.requestItem = new QueryStore((args) => this.sdk.request.get(args));
    this.requestList = new QueryStore((args) => this.sdk.request.find(args));
    this.requestTotal = new QueryStore((args) => this.sdk.request.total(args));
    this.requestCreate = new MutationStore((args) => this.sdk.request.create(args));
    this.requestExists = new QueryStore((args) => this.sdk.request.exists(args));

    this.courseItem = new QueryStore((args) => this.sdk.course.get(args));
    this.courseList = new QueryStore((args) => this.sdk.course.find(args));
    this.courseTotal = new QueryStore((args) => this.sdk.course.total(args));
    this.createCourse = new MutationStore((args) => this.sdk.course.create(args));
    this.updateCourse = new MutationStore((args) => this.sdk.course.update(args));
    this.approveCourse = new MutationStore((args) => this.sdk.courseModeration.approve(args));
    this.declineCourse = new MutationStore((args) => this.sdk.courseModeration.decline(args));
    this.unPublishCourse = new MutationStore((args) => this.sdk.course.unPublish(args));

    this.courseModerationItem = new QueryStore((args) => this.sdk.courseModeration.get(args));

    this.user = new QueryStore((args) => this.sdk.user.login(args));
    this.organization = new QueryStore(() => this.sdk.organization.current());

    this.userList = new QueryStore((args) => this.sdk.user.find(args));
    this.userItem = new QueryStore((args) => this.sdk.user.get(args));
    this.userTotal = new QueryStore((args) => this.sdk.user.total(args));
    this.userCreate = new MutationStore((args) => this.sdk.user.create(args));
    this.userDelete = new MutationStore((args) => this.sdk.user.delete(args));
    this.userOneIdList = new QueryStore((args) => this.sdk.user.findOneId(args));
    this.userChangeRole = new MutationStore((args) => this.sdk.user.changeRole(args));
    this.userUpdateAttachments = new MutationStore((args) => this.sdk.user.updateAttachments(args));
    this.userModeration = new QueryStore((args) => this.sdk.userModeration.get(args));

    this.organizationList = new QueryStore((args) => this.sdk.organization.find(args));
    this.organizationListPart = new QueryStore((args) => this.sdk.organization.findPart(args));
    this.organizationItem = new QueryStore((args) => this.sdk.organization.get(args));
    this.organizationTotal = new QueryStore((args) => this.sdk.organization.total(args));
    this.organizationUpdate = new MutationStore((args) => this.sdk.organization.update(args));
    this.organizationCreate = new MutationStore((args) => this.sdk.organization.create(args));

    this.documentItem = new QueryStore((args) => this.sdk.document.get(args));
    this.documentList = new QueryStore((args) => this.sdk.document.find(args));
    this.documentTotal = new QueryStore((args) => this.sdk.document.total(args));
    this.documentCreate = new MutationStore((args) => this.sdk.document.create(args));
    this.documentSign = new MutationStore((args) => this.sdk.document.sign(args));
    this.documentDelete = new MutationStore((args) => this.sdk.document.delete(args));
    this.documentUpload = this.sdk.document.uploadUrl;

    this.competency = new QueryStore(() => this.sdk.competency.find());
    this.programingLanguage = new QueryStore(() => this.sdk.programingLanguage.find());

    this.userCourseList = new QueryStore((args) => this.sdk.userCourse.find(args));
    this.userCourseTotal = new QueryStore((args) => this.sdk.userCourse.total(args));
    this.userCourse = new QueryStore((args) => this.sdk.userCourse.get(args));

    this.courseReflection = new MutationStore((args) => this.sdk.courseReflection.create(args));
    this.courseReflectionExists = new QueryStore((args) => this.sdk.courseReflection.exists(args));
  }

  public refresh(): void {
    this.sdk.token = this.rootStore.auth.token;
  }

}
