import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Dialog, DialogFooter, DialogHeader } from '@unione-pro/unione.digital.commons.ui-kit';
import { DateField } from '@unione-pro/unione.digital.commons.ui-kit/lib/date-field';
import { FileRow } from '@unione-pro/unione.digital.commons.ui-kit/lib/file-row';
import { FormLabel } from '@unione-pro/unione.digital.commons.ui-kit/lib/form-label';
import { AddIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/add';
import { UploadIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/upload';
import { UserIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/user';
import { Select, SelectItem } from '@unione-pro/unione.digital.commons.ui-kit/lib/select';
import { onErrorSnackbar } from '@unione-pro/unione.digital.commons.ui-kit/lib/snackbar/snackbar.utils';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { UploadFile } from '@unione-pro/unione.digital.commons.ui-kit/lib/upload-file';
import cn from 'classnames';
import { getFileName } from '../../../shared/format/get-file-name';
import { useAppStore } from '../../../stores/root/root.context';
import { DialogDocumentUploadProps } from './dialog-document-upload.models';

const PREFIX = 'DialogDocumentUpload';
const useStyles = createUseStyles(() => ({ root: { maxWidth: 650 } }), { name: PREFIX });

export const DialogDocumentUpload: React.FC<DialogDocumentUploadProps> = (exampleProps) => {
  const { className, onClose, onSubmit, title, files: initialFiles, ...other } = exampleProps;

  const { digital, auth } = useAppStore();
  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const uploadUrl = digital.sdk.user.uploadAttachment;

  const [files, setFiles] = React.useState<string[]>(initialFiles);
  const [isNew, setIsNew] = React.useState(false);

  const handleSubmit = (): void => {
    if (onSubmit && files.length) {
      onSubmit(files);
    }
  };

  React.useEffect(() => {
    setFiles(initialFiles);
    setIsNew(initialFiles.length === 0);
  }, [initialFiles]);

  return (
    <Dialog color="primary" className={rootCN} onClose={onClose} {...other}>
      <form
        onSubmit={(e): void => {
          e.preventDefault();
          e.stopPropagation();

          handleSubmit();
        }}
      >
        <DialogHeader onClose={onClose}>
          <UploadIcon color="primary" style={{ width: '28px', height: '28px' }} />
        </DialogHeader>

        <Typography variant="h3" style={{ fontWeight: 600, marginTop: '40px' }}>
          {title}
        </Typography>

        <Stack direction="column" spacing={12} style={{ marginTop: 40 }}>
          {files.map((file, i) => (
            <FileRow
              key={`file-${file}`}
              label={getFileName(file)}
              href={file}
              onDelete={(): void => setFiles(files.filter((_, i2) => i !== i2))}
            />
          ))}

          {isNew && (
            <UploadFile
              style={{ marginTop: 16 }}
              authorization={auth.token}
              action={uploadUrl}
              onChange={(v): void => {
                setFiles([...files, v]);
                setIsNew(false);
              }}
              onError={onErrorSnackbar}
            />
          )}

          {!isNew && (
            <Button
              variant="standard"
              startIcon={<AddIcon />}
              style={{ marginLeft: 'auto' }}
              onClick={(): void => setIsNew(true)}
            >
              Добавить файл
            </Button>
          )}
        </Stack>

        <DialogFooter style={{ marginTop: '40px', marginBottom: '-16px' }}>
          <Stack direction="column" alignItems="center" style={{ flex: 1 }}>
            <Button
              fullWidth
              variant="gradient"
              color="primary"
              size="large"
              style={{ justifyContent: 'center' }}
              disabled={files.length === 0}
              type="submit"
            >
              Загрузить файлы
            </Button>
            <Button
              fullWidth
              variant="standard"
              color="gray"
              size="large"
              style={{ justifyContent: 'center' }}
              onClick={onClose}
            >
              Отмена
            </Button>
          </Stack>
        </DialogFooter>
      </form>
    </Dialog>
  );
};
