import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import { UserCourseStatus } from '@unione-pro/unione.digital.commons.sdk';
import { CircularProgress } from '@unione-pro/unione.digital.commons.ui-kit/lib/circular-progress';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import {
  TablePagination,
  useTablePaginationState,
} from '@unione-pro/unione.digital.commons.ui-kit/lib/table-pagination';
import { Tab, Tabs } from '@unione-pro/unione.digital.commons.ui-kit/lib/tabs';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useAppStore } from '../../../stores/root/root.context';
import { CourseCard } from '../../components/course-card';

const PREFIX = 'MyCoursesPage';
const useStyles = createUseStyles(
  () => ({
    root: {},
    pagination: {
      marginTop: 16,
      gap: 20,
      marginLeft: 'auto',
      width: '100%',
    },
  }),
  { name: PREFIX },
);

export const MyCoursesPage: FC = observer(() => {
  const classes = useStyles();
  const { digital } = useAppStore();
  const location = useLocation();

  const isFinished = location.pathname === BrowserRoute.coursesMyFinished;

  const pagination = useTablePaginationState(0, 10);

  const { data: user } = digital.user.useQuery({ variables: {} });

  const { data: courses, loading } = digital.userCourseList.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      status: isFinished ? UserCourseStatus.trained : UserCourseStatus.enrolled,
      user_id: user?._id,
    },
    skip: !user,
  });

  const { data: total = 0 } = digital.userCourseTotal.useQuery({
    variables: {
      status: isFinished ? UserCourseStatus.trained : UserCourseStatus.enrolled,
      user_id: user?._id,
    },
  });

  const rows = courses || [];
  const isEmpty = rows.length === 0;

  return (
    <Paper style={{ padding: '32px 40px' }}>
      <Typography style={{ marginBottom: 18 }} variant="h2" fontWeight="bolder">
        Мои курсы
      </Typography>

      <Tabs value={location.pathname}>
        <Tab
          label="Текущие"
          value={BrowserRoute.coursesMy}
          selected={BrowserRoute.coursesMy === location.pathname}
          component={(p): JSX.Element => <Link to={BrowserRoute.coursesMy} {...p} />}
        />
        <Tab
          label="Прошедшие"
          value={BrowserRoute.coursesMyFinished}
          selected={BrowserRoute.coursesMyFinished === location.pathname}
          component={(p): JSX.Element => <Link to={BrowserRoute.coursesMyFinished} {...p} />}
        />
      </Tabs>

      <div style={{ display: 'flex', gap: 24, flexWrap: 'wrap', marginTop: 32 }}>
        {!loading &&
          rows.map(({ course, stream }) => (
            <Link
              key={`course-${course._id}`}
              to={BrowserRoute.course(course._id)}
              style={{ display: 'block', flexBasis: 'calc(50% - 12px)' }}
            >
              <CourseCard course={course} stream={stream} />
            </Link>
          ))}
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <CircularProgress />
          </div>
        )}
        {isEmpty && !loading && (
          <Typography style={{ display: 'flex', width: '100%', marginBottom: 8 }}>Курсы не найдены</Typography>
        )}
      </div>

      {(total > 10 || isEmpty) && (
        <TablePagination
          className={classes.pagination}
          page={pagination.page}
          total={total}
          rowsPerPage={pagination.rowsPerPage}
          onChangePage={pagination.onChangePage}
          onChangeRowsPerPage={pagination.onChangeRowsPerPage}
        />
      )}
    </Paper>
  );
});
