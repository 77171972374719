import React from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { CourseStatus } from '@unione-pro/unione.digital.commons.sdk';
import { IDigitalCourseReflection } from '@unione-pro/unione.digital.commons.sdk/lib/digital/course-reflection/types';
import { Button } from '@unione-pro/unione.digital.commons.ui-kit';
import { useDialogState } from '@unione-pro/unione.digital.commons.ui-kit/lib/dialog/dialog.hook';
import { BookOpenIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/book-open';
import { ChartAltIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/chart-alt';
import { CheckIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/check';
import { CloseIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/close';
import { DoneIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/done';
import { ListIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/list';
import { ResultsIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/results';
import { Image } from '@unione-pro/unione.digital.commons.ui-kit/lib/image/image.view';
import { onErrorSnackbar, snackbar } from '@unione-pro/unione.digital.commons.ui-kit/lib/snackbar/snackbar.utils';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { Value } from '@unione-pro/unione.digital.commons.ui-kit/lib/value';
import cn from 'classnames';
import Color from 'color';
import { observer } from 'mobx-react';
import { courseDifficultyToLocale, courseLearnFormatToLocale } from '../../../shared/course/format';
import { getRandomDecor } from '../../../shared/get-random-decor';
import { toHumanDate } from '../../../shared/human-date';
import { useAppStore } from '../../../stores/root/root.context';
import { DialogReflectionConfirm } from '../dialog-reflection-confirm';
import { DialogReflection } from '../dialog-reflection/dialog-reflection.view';
import { BannerProps } from './banner.models';

const PREFIX = 'Banner';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      height: 'auto',
      backgroundImage: 'url(/static/banner.png)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      position: 'relative',
    },
    title: {
      color: '#ffffff',
    },
    status: {
      position: 'absolute',
      right: 24,
      top: 24,
    },
    overlay: {
      width: '100%',
      height: 'auto',
      background: Color('#3B4168').toString(),

      padding: '40px 40px 40px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    reflexBtn: {
      whiteSpace: 'nowrap',
      width: 300,
      padding: '12px 24px',
      fontSize: '16px',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 8,
      fontWeight: 500,
      borderColor: 'white',
      color: 'white',
      '&:hover': {
        backgroundColor: Color('#3B4168').darken(0.2).toString(),
      },
      '& > div': {
        height: 20,
      },
    },
    applicationBtn: {
      width: 200,
      padding: '12px 24px',
      fontSize: '16px',
      justifyContent: 'center',
      marginTop: 8,
      fontWeight: 500,
    },
    logo: {
      width: '100%',
      maxWidth: '496px',
      maxHeight: '332px',
      backgroundImage: `url(${getRandomDecor()})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      transform: 'rotate(8deg)',
      marginRight: '10%',
      '& > img': {
        height: '180px',
        maxWidth: '242px',
        borderRadius: '40px',
      },
    },
  }),
  { name: PREFIX },
);

export const Banner: React.FC<BannerProps> = observer((exampleProps) => {
  const { className, status, image, style = {}, course, ...other } = exampleProps;
  const { digital } = useAppStore();

  const classes = useStyles();
  const rootCN = cn(classes.root, className);
  const params = useParams();

  const { data: userCourse, loading: loadingUserCourse } = digital.userCourse.useQuery({ variables: { course_id: params.id } });
  const { data: application, loading: loadingApplication, refetch: refetchApplication } = digital.requestExists.useQuery({
    variables: { course_id: params.id },
  });
  const { data: courseReflection, loading: loadingCourseReflection, refetch: refetchReflection } = digital.courseReflectionExists.useQuery({
    variables: { course_id: params.id },
  });

  const [createReflection] = digital.courseReflection.useMutation({
    onError: onErrorSnackbar,
  });
  const [createRequest] = digital.requestCreate.useMutation({
    onError: onErrorSnackbar,
  });

  const dialogReflectionConfirm = useDialogState();
  const dialogReflection = useDialogState();

  const confirmReflection = (): void => {
    dialogReflectionConfirm.close();
    dialogReflection.open();
  };

  const onSubmit = (reflection: IDigitalCourseReflection): void => {
    createReflection({ ...reflection, course_id: params.id }).then(() => {
      dialogReflection.close();
      snackbar({ message: 'Ваша рефлексия отправлена', severity: 'success' });
      refetchReflection();
    });
  };
  const handleCreate = (): void => {
    createRequest({ course_id: course._id }).then((): void => {
      snackbar({ message: 'Заявка создана', severity: 'success' });
      refetchApplication();
    });
  };

  const allLoading = loadingUserCourse || loadingCourseReflection || loadingApplication;
  const isReflectionBtn = !!userCourse && !courseReflection && !allLoading;
  const isAlready = !!application && !userCourse && !allLoading;
  const isNoUser = !application && !userCourse && !allLoading && status === CourseStatus.published;
  const isCompeted = !!userCourse && !!courseReflection && !allLoading;
  const isNotPublished = status !== CourseStatus.published;

  const first = [...(course?.streams || [])].sort((a, b) => (a.started_at < b.started_at ? -1 : 1))[0];

  return (
    course && (
      <div className={rootCN} style={{ backgroundImage: `url(${image})`, ...style }} {...other}>
        <div className={classes.overlay}>
          <Stack direction="column" spacing={32}>
            <Typography variant="h1" fontWeight="bold" className={classes.title}>
              {course.name}
            </Typography>

            <Stack direction="row" spacing={34}>
              <Value
                title="формат обучения"
                icon={<DoneIcon />}
                value={
                  <Typography fontWeight="medium" style={{ color: '#fff' }}>
                    {courseLearnFormatToLocale(course.learn_format)}
                  </Typography>
                }
              />

              <Value
                title="продолжительность"
                icon={<BookOpenIcon />}
                value={
                  <Typography fontWeight="medium" style={{ color: '#fff' }}>
                    {course.learn_hours} часов
                  </Typography>
                }
              />

              <Value
                title="поток"
                icon={<ListIcon />}
                value={
                  <Typography fontWeight="medium" style={{ color: '#fff' }}>
                    {first && first.started_at ? toHumanDate(first.started_at) : '-'}
                  </Typography>
                }
              />

              <Value
                title="сложность"
                icon={<ResultsIcon />}
                value={
                  <Typography fontWeight="medium" style={{ color: '#fff' }}>
                    {courseDifficultyToLocale(course.difficulty)}
                  </Typography>
                }
              />
            </Stack>

            {isReflectionBtn && (
              <Button
                className={classes.reflexBtn}
                variant="outlined"
                startIcon={<ChartAltIcon style={{ padding: 0, width: 20, height: 20 }} />}
                onClick={(): void => dialogReflectionConfirm.open()}
              >
                Итоговая рефлексия по курсу
              </Button>
            )}
            {isAlready && (
              <Typography style={{ color: 'white', display: 'flex', alignItems: 'center', gap: 10 }}>
                <CheckIcon />
                Заявка уже подана
              </Typography>
            )}
            {isCompeted && (
              <Typography style={{ color: 'white', display: 'flex', alignItems: 'center', gap: 10 }}>
                <CheckIcon />
                Курс пройден
              </Typography>
            )}
            {isNoUser && (
              <Button className={classes.applicationBtn} color="primary" variant="contained" onClick={handleCreate}>
                Подать заявку
              </Button>
            )}
            {isNotPublished && (
              <Typography style={{ color: 'white', display: 'flex', alignItems: 'center', gap: 10 }}>
                <CloseIcon />
                Курс не опубликован
              </Typography>
            )}
          </Stack>

          {course.media && (
            <div className={classes.logo}>
              <Image className={classes.img} src={course.media} objectFit="cover" />
            </div>
          )}
        </div>

        <DialogReflectionConfirm {...dialogReflectionConfirm.props} onSubmit={confirmReflection} />
        <DialogReflection {...dialogReflection.props} onSubmit={onSubmit} />
      </div>
    )
  );
});
