import React from 'react';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { PageMessage } from '../../lab/page-message';

export const AccessError: React.FC = () => (
  <PageMessage
    message={
      <div>
        <Typography variant="h2" align="center" fontWeight="bold">
          К сожалению у вас нет доступа к сервису.
        </Typography>
        <Typography variant="h5" align="center">
          Обратитесь к ответственному в вашей организации
        </Typography>
      </div>
    }
  />
);
