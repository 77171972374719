import React, { FC } from 'react';
import { ThemeProvider } from 'react-jss';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { Wrapper } from '@unione-pro/unione.commons.webpack';
import { ErrorBoundary } from '@unione-pro/unione.digital.commons.ui-kit/lib/error-boundary';
import { Snackbar } from '@unione-pro/unione.digital.commons.ui-kit/lib/snackbar';
import { appTheme } from '@unione-pro/unione.digital.commons.ui-kit/lib/theme';
import '@unione-pro/unione.digital.commons.ui-kit/lib/index.css';
import { appRoutesDefinition } from './routes/app.routes';
import { Access } from './views/components/app-access';
import { Auth } from './views/components/app-auth';

const Content: React.FC = () => {
  const appRoutes = useRoutes(appRoutesDefinition);

  return appRoutes;
};

export const App: FC = () => (
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <ThemeProvider theme={appTheme}>
          <Snackbar />
          <Auth>
            <Access>
              <Wrapper>
                <Content />
              </Wrapper>
            </Access>
          </Auth>
        </ThemeProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);
