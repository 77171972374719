import React from 'react';
import { createUseStyles } from 'react-jss';
import { Avatar } from '@unione-pro/unione.digital.commons.ui-kit/lib/avatar';
import { PhotoAddIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/photo-add';
import cn from 'classnames';
import { AvatarProfileProps } from './avatar-profile.models';

const PREFIX = 'AvatarProfile';
const useStyles = createUseStyles(
  () => ({
    root: {
      padding: 0,
      width: 168,
      height: 168,

      '& > svg': {
        width: 70,
        height: 70,
        color: '#99C6EB',
      },
    },
    empty: {
      border: '2px dashed #99C6EB',
    },
  }),
  { name: PREFIX },
);

export const AvatarProfile: React.FC<AvatarProfileProps> = (exampleProps) => {
  const { className, src, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.empty]: !src }, className);

  return (
    <Avatar src={src} className={rootCN} {...other}>
      <PhotoAddIcon />
    </Avatar>
  );
};
