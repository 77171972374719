import React, { FC, Fragment, PropsWithChildren, useContext } from 'react';
import { DigitalUserRole } from '@unione-pro/unione.digital.commons.sdk';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/root/root.context';
import { LayoutSimple } from '../app-layout/layout-simple.view';
import { AccessError } from './access-error.view';

export const Access: FC<PropsWithChildren> = observer((props) => {
  const { digital } = useContext(appStoreContext);
  const { data } = digital.user.useQuery({ variables: {}, skip: true });

  if (!data) {
    return (
      <LayoutSimple>
        <AccessError />
      </LayoutSimple>
    );
  }

  return <Fragment>{props.children}</Fragment>;
});
