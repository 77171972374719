import React from 'react';
import { createUseStyles } from 'react-jss';
import { Accordion, AccordionDetails, AccordionSummary } from '@unione-pro/unione.digital.commons.ui-kit/lib/accordion';
import { Checkbox } from '@unione-pro/unione.digital.commons.ui-kit/lib/checkbox';
import { CircularProgress } from '@unione-pro/unione.digital.commons.ui-kit/lib/circular-progress';
import { Divider } from '@unione-pro/unione.digital.commons.ui-kit/lib/divider';
import { FormControlLabel } from '@unione-pro/unione.digital.commons.ui-kit/lib/form-control-label';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import cn from 'classnames';
import { CourseFilterProps } from './course-filter.models';

// export const competencyAreas: readonly string[] = [
//   'Большие данные',
//   'Интернет вещей',
//   'Искусственный интеллект',
//   'Квантовые технологии',
//   'Кибербезопасность и защита данных',
//   'Нейротехнологии, виртуальная и дополненная реальность',
//   'Новые и портативные источники энергии',
//   'Новые производственные технологии',
//   'Программирование и создание IT-продуктов',
//   'Промышленный дизайн и 3D-моделирование',
//   'Промышленный интернет',
//   'Разработка компьютерных игр и мультимедийных приложений',
//   'Разработка мобильных приложений',
//   'Распределение и облачные вычисления',
//   'Сенсорика и компоненты робототехники',
//   'Системное администрирование',
//   'Системы распределенного реестра',
//   'Технологии беспроводной связи',
//   'Технологии управления свойствами биологических объектов',
//   'Управление цифровой трансформацией',
//   'Управление, основанное на данных',
//   'Цифровой дизайн',
//   'Цифровой маркетинг и медиа',
//   'Электроника и радиотехника',
// ];

const PREFIX = 'CourseFilter';
const useStyles = createUseStyles(
  () => ({
    root: { minWidth: 320, maxWidth: 320, height: 'fit-content' },
    summary: { '&:hover': { background: 'transparent' } },
    details: {
      padding: '16px 24px',
      paddingTop: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
  }),
  {
    name: PREFIX,
  },
);

export const CourseFilter: React.FC<CourseFilterProps> = (exampleProps) => {
  const {
    className,
    competencyOptions,
    languageOptions,
    loading,
    selectedCompetencies,
    selectedLanguages,
    onChangeCompetencies,
    onChangeLanguages,
    ...other
  } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const [isCompetency, setIsCompetency] = React.useState(true);
  const [isLang, setIsLang] = React.useState(false);

  const mapCompetencies = React.useMemo(
    () => selectedCompetencies.reduce((acc, el) => ({ ...acc, [el._id]: true }), {}),
    [selectedCompetencies],
  );
  const mapLanguages = React.useMemo(
    () => selectedLanguages.reduce((acc, el) => ({ ...acc, [el._id]: true }), {}),
    [selectedLanguages],
  );

  return (
    <Paper variant="outlined" className={rootCN} {...other}>
      <Accordion expanded={isCompetency} onExpand={(v): void => setIsCompetency(v)}>
        <AccordionSummary label="Компетенции" className={classes.summary} />
        <AccordionDetails className={classes.details}>
          {competencyOptions
            .filter((el): boolean => el.courseCount > 0)
            .map((competency) => (
              <label key={`competency-${competency._id}`} className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mapCompetencies[competency._id]}
                      onChange={(e): void => {
                        if (onChangeCompetencies) {
                          if (!e.target.checked) {
                            onChangeCompetencies(selectedCompetencies.filter((el) => el._id !== competency._id));
                          }
                          else {
                            onChangeCompetencies([...selectedCompetencies, competency]);
                          }
                        }
                      }}
                    />
                  }
                  label={<Typography variant="caption">{competency.title}</Typography>}
                />
                {!!competency.courseCount && (
                  <Typography variant="caption" color="textSecondary" style={{ marginLeft: 4 }}>
                    ({competency.courseCount})
                  </Typography>
                )}
              </label>
            ))}
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <CircularProgress />
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      <Divider />

      <Accordion expanded={isLang} onExpand={(v): void => setIsLang(v)}>
        <AccordionSummary label="Язык программирования" className={classes.summary} />
        <AccordionDetails className={classes.details}>
          {languageOptions
            .filter((el): boolean => el.courseCount > 0)
            .map((language) => (
              <label key={`lang-${language._id}`} className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mapLanguages[language._id]}
                      onChange={(e): void => {
                        if (onChangeLanguages) {
                          if (!e.target.checked) {
                            onChangeLanguages(selectedLanguages.filter((el) => el._id !== language._id));
                          }
                          else {
                            onChangeLanguages([...selectedLanguages, language]);
                          }
                        }
                      }}
                    />
                  }
                  label={<Typography variant="caption">{language.title}</Typography>}
                />
                {!!language.courseCount && (
                  <Typography variant="caption" color="textSecondary" style={{ marginLeft: 4 }}>
                    ({language.courseCount})
                  </Typography>
                )}
              </label>
            ))}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
