import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { DigitalUserModerationSectionKey } from '@unione-pro/unione.digital.commons.sdk';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { PageLoadingView } from '../../lab/page-loading';
import { PageNotFound } from '../../lab/page-not-found';
import { UserDocumentsPreview } from './user-documents.preview';

const PREFIX = 'OrganizationView';
const useStyles = createUseStyles(
  () => ({
    root: {},
  }),
  { name: PREFIX },
);

export const DocumentsPage: FC = observer(() => {
  const classes = useStyles();
  const { digital } = useAppStore();

  const { data: user, loading } = digital.user.useQuery({ variables: {}, skip: true });
  const { data: userModeration } = digital.userModeration.useQuery({ variables: { user_id: user?._id }, skip: !user });

  if (loading) {
    return <PageLoadingView />;
  }

  if (!user && !loading) {
    return <PageNotFound />;
  }

  const errorSection = userModeration?.sections?.find((el) => el.key === DigitalUserModerationSectionKey.documents);

  return (
    <Paper style={{ padding: '32px 40px' }}>
      <Typography variant="h2" fontWeight="bolder">
        Мои документы
      </Typography>

      <UserDocumentsPreview user={user} errorMessage={errorSection?.comment} />
    </Paper>
  );
});
