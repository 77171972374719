export class BrowserRoute {

  public static main = '/';

  public static notFound = '/not-found';

  public static courses = '/';
  public static coursesMy = '/course/my';
  public static coursesMyFinished = '/course/my/finished';

  public static documents = '/document';

  public static profile = '/profile';

  public static course = (id: string = ':id'): string => `/course/${id}`;
  public static courseCompetencies = (id: string = ':id'): string => `${BrowserRoute.course(id)}/competency`;
  public static courseModules = (id: string = ':id'): string => `${BrowserRoute.course(id)}/module`;
  public static courseTeachers = (id: string = ':id'): string => `${BrowserRoute.course(id)}/teachers`;

}
