export const formatPhone = (str: string): string => {
  if (!str) {
    return '';
  }

  const country = str.slice(0, 1);
  const code = str.slice(1, 4);
  const one = str.slice(4, 7);
  const two = str.slice(7, 9);
  const three = str.slice(9, 11);

  return `${country} (${code}) ${one}-${two}-${three}`;
};
